import React from "react"
import {langFromPath} from "../i18n-config"
import {Location} from "@reach/router"

export default Component => props => (
  <Location>
    {({ location }) => {
      const lang = langFromPath(location.pathname)
      return <Component {...props} lang={lang} />
    }}
  </Location>
)
