import React from "react"
import {navigateTo} from "gatsby-link"
import browserLang from "browser-lang"

import "prismjs/themes/prism-coy.css"
import "../styles.css"

import {rhythm} from "../utils/typography"
import {defaultLanguage, langFromPath, languages, localizedPath,} from "../i18n-config"
import LangSwitcher from "../components/LangSwitcher"
import Menu from "../components/Menu"
import Title from "../components/Title"

class Layout extends React.Component {
  componentDidMount() {
    // Skip build, Browsers only
    if (typeof window !== "undefined") {
      const { pathname } = this.props.location
      const detected =
        window.localStorage.getItem("language") ||
        browserLang({
          languages: languages,
          fallback: defaultLanguage,
        })
      window.localStorage.setItem("language", detected)
      if (detected !== langFromPath(pathname)) {
        navigateTo(localizedPath(pathname, detected))
      }
    }
  }

  render() {
    const { location, children } = this.props
    const lang = langFromPath(location.pathname)
    return (
      <div className="bg">
        <div
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            maxWidth: rhythm(28),
            padding: `${rhythm(1.5)} 0`,
          }}
        >
          <div
            className="header"
            style={{
              padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
              position: "relative",
            }}
          >
            <LangSwitcher
              lang={lang}
              pathname={location.pathname}
              style={{
                position: "absolute",
                right: rhythm(1.5),
                top: rhythm(3 / 4),
              }}
            />
            <Title />
            <Menu />
          </div>
          <div
            className="content"
            style={{
              padding: `${rhythm(0.1)} ${rhythm(3 / 4)} ${rhythm(1.5)} ${rhythm(
                3 / 4
              )}`,
              marginTop: `${rhythm(1)}`,
            }}
          >
            {children}
          </div>
          <div
            className="footer"
            style={{
              padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
              marginTop: `${rhythm(1)}`,
            }}
          >
            <div>
              <a href="https://twitter.com/agamestudio_">
                <svg
                  width={20}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 612 612"
                  style={{
                    verticalAlign: "middle",
                    marginRight: "0.5rem",
                    paddingBottom: 2,
                  }}
                >
                  <path
                    style={{ fill: "#333333" }}
                    d="M612,116.256c-22.526,9.981-46.694,16.75-72.088,19.772c25.929-15.527,45.777-40.155,55.184-69.411
			c-24.322,14.379-51.169,24.82-79.775,30.48c-22.907-24.436-55.49-39.658-91.63-39.658c-69.334,0-125.551,56.217-125.551,125.513
			c0,9.828,1.109,19.427,3.251,28.606C197.065,206.32,104.556,156.337,42.641,80.386c-10.823,18.51-16.98,40.078-16.98,63.101
			c0,43.559,22.181,81.994,55.835,104.479c-20.575-0.688-38.926-6.348-56.867-15.756v1.568c0,60.805,43.291,111.554,100.693,123.104
			c-10.517,2.83-21.607,4.398-33.08,4.398c-8.107,0-15.947-0.803-23.634-2.333c15.985,49.907,62.336,86.199,117.253,87.194
			c-42.947,33.654-97.099,53.655-155.916,53.655c-10.134,0-20.116-0.612-29.944-1.721c55.567,35.681,121.536,56.485,192.438,56.485
			c230.948,0,357.188-191.292,357.188-357.188l-0.421-16.253C573.872,163.526,595.211,141.422,612,116.259z"
                  />
                </svg>
                <span>@agamestudio_</span>
              </a>
              <span style={{ padding: "0 1rem" }}> </span>
              <a href="mailto:contact@a-game-studio.com">
                <svg
                  width={20}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 330 330"
                  style={{
                    verticalAlign: "middle",
                    paddingBottom: 2,
                    marginRight: "0.5rem",
                    fill: "#333333",
                  }}
                >
                  <path
                    d="M173.871,177.097c-2.641,1.936-5.756,2.903-8.87,2.903c-3.116,0-6.23-0.967-8.871-2.903L30,84.602
		L0.001,62.603L0,275.001c0.001,8.284,6.716,15,15,15L315.001,290c8.285,0,15-6.716,15-14.999V62.602l-30.001,22L173.871,177.097z"
                  />
                  <polygon points="165.001,146.4 310.087,40.001 19.911,40 	" />
                </svg>
                <span>contact@a-game-studio.com</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Layout
