import React from "react"
import {rhythm} from "../utils/typography"
import LocalizedLink from "./LocalizedLink"
import withLang from "./withLang"

export default withLang(({ lang }) => (
  <nav
    style={{
      height: rhythm(4),
      lineHeight: rhythm(4),
    }}
  >
    <ul
      className="menu"
      style={{
        float: "right",
        margin: 0,
        listStyle: "none",
      }}
    >
      <li>
        <LocalizedLink to="/about">
          <span>{lang === "fr" ? "A propos" : "About"}</span>
        </LocalizedLink>
      </li>
      <li>
        <LocalizedLink to="/projects">
          <span>{lang === "fr" ? "Projets" : "Projects"}</span>
        </LocalizedLink>
      </li>
      <li>
        <LocalizedLink to="/">
          <span>Blog</span>
        </LocalizedLink>
      </li>
    </ul>
  </nav>
))
