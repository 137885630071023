import Typography from "typography"

const theme = {
  title: "A Game Studio",
  baseFontSize: "18px",
  baseLineHeight: 1.5,
  headerFontFamily: ["Lato", "serif"],
  bodyFontFamily: ["Lato", "serif"],
  bodyColor: "hsla(0,0%,0%,0.83)",
  headerWeight: 600,
  bodyWeight: 400,
  boldWeight: 600,
  overrideThemeStyles: ({ adjustFontSizeTo, scale, rhythm }, options) => ({
    "h1,h2,h3,h4,h5,h6": {
      marginTop: rhythm(1.5),
      marginBottom: rhythm(0.5),
    },
    "ul,ol": {
      marginLeft: rhythm(5 / 6),
    },
    // children ol, ul
    "li>ol,li>ul": {
      marginLeft: rhythm(5 / 6),
      marginBottom: 0,
    },
    // Blockquote styles.
    blockquote: {
      ...scale(1 / 5),
      fontWeight: 300,
      color: "#666666",
      fontStyle: "italic",
      paddingLeft: rhythm(1),
      marginLeft: 0,
      marginRight: 0,
      borderLeft: "4px solid",
    },
    "blockquote > :last-child": {
      marginBottom: 0,
    },
    "blockquote cite": {
      ...adjustFontSizeTo(options.baseFontSize),
      color: options.bodyColor,
      fontStyle: "normal",
      fontWeight: options.bodyWeight,
    },
    "blockquote cite:before": {
      content: '"— "',
    },
  }),
}

const typography = new Typography(theme)

// Hot reload typography in development.
if (process.env.NODE_ENV !== "production") {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
