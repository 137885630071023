import React from "react"
import Link from "gatsby-link"
import {localizedPath} from "../i18n-config"

export default ({ lang, pathname, style }) => (
  <div style={style}>
    {lang === "fr" && (
      <Link
        to={localizedPath(pathname, "en")}
        className="langSwitcher"
        onClick={() =>
          window.localStorage && window.localStorage.setItem("language", "en")
        }
      >
        <span>Read in English</span>
      </Link>
    )}
    {lang === "en" && (
      <Link
        to={localizedPath(pathname, "fr")}
        className="langSwitcher"
        onClick={() =>
          window.localStorage && window.localStorage.setItem("language", "fr")
        }
      >
        <span>Lire en Français</span>
      </Link>
    )}
  </div>
)
