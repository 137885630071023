import {withPrefix} from "gatsby-link"

export const languages = ["en", "fr"]
export const defaultLanguage = "en"
export const langFromPath = pathname =>
  pathname.startsWith("/fr/") || pathname === "/fr" ? "fr" : "en"
const switchLangInPath = (pathname, target) => {
  const pathLanguage = langFromPath(pathname)
  // swtich to fr
  if (target === "fr" && pathLanguage === "en") {
    return withPrefix(`/fr${pathname === "/" ? "" : pathname}`)
  }
  // switch to en
  if (target === "en" && pathLanguage === "fr") {
    return pathname.replace("/fr", "")
  }
  return pathname
}

export const localizedPath = switchLangInPath

export function formatDate(dateStr, lang) {
  return new Date(dateStr).toLocaleDateString(lang, {
    year: "numeric",
    month: "long",
    day: "numeric",
  })
}
